.RichEditor-root {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-family: 'Zen Kaku Gothic Antique', sans-serif;
  font-size: 14px;
  padding: 0 15px;
  padding-bottom: 10px;
  margin-bottom: 30px;
}

.RichEditor-editor {
  cursor: text;
  word-break: break-word;
  width: 100%;
  font-size: 16px;
  margin-top: 10px;
}

.RichEditor-display {
  font-family: 'Zen Kaku Gothic Antique', sans-serif;
  padding: 15px;
  border-radius: 4px;
  cursor: text;
  word-break: break-word;
  font-size: 16px;
  background-color: #f9f9fb;
  margin-bottom: 30px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 300px;
  max-height: 500px;
  overflow: auto;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: 'Hoefler Text', 'Georgia', serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin: 0 8px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: #f36f60;
}

.RichEditor-select {
  padding: 7px;
  margin-right: 15px;
  outline: none;
  border: none;
  border-bottom: 1px solid;
}

.RichEditor-select:focus {
  border-color: #f36f60;
}

/* fonts  */
.font-size-menu {
  display: inline-block;
  position: absolute;
  top: 3px;
  background: #ffffff;
  box-shadow: 0 0 2px;
  max-height: 150px;
  overflow-y: scroll;
}

.font-size-menu::-webkit-scrollbar {
  display: none;
}

.font-size-option {
  padding: 3px;
}

.font-size-option:hover {
  background: #f0f0f0;
  cursor: pointer;
}

/* font sizes  */
.font-size-10 {
  font-size: 14px;
}
.font-size-12 {
  font-size: 16px;
}
.font-size-14 {
  font-size: 19px;
}
.font-size-18 {
  font-size: 24px;
}
.font-size-24 {
  font-size: 32px;
}
.font-size-30 {
  font-size: 40px;
}
.font-size-34 {
  font-size: 45px;
}
